import React from 'react'
import PropTypes from 'prop-types'
import LayoutListView from '../../components/LayoutListView'
import ComparisonTable from '../../components/ComparisonTable'

const Comparison = ({ location }) => (
  <LayoutListView path={location.pathname} configID="7mwG4QSFXqmEZncfYaK9BF">
    <ComparisonTable />
  </LayoutListView>
)

export default Comparison

Comparison.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
